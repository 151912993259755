import React from "react"
import srcBackground from "../../../images/bg-customization-banner.png"
import srcIconIndicator from "../../../images/icon-indicator.png"
import styles from "./styles.module.scss"

export default function CustomizationBanner({time}) {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.title}>
          Enjoy Our Customization Suite
        </div>
        <div className={styles.subTitle}>
          We offer your business the best program for cause-affinity, proven to be effective in enhancing customer loyalty.
        </div>
        <div className={styles.description}>
          Our robust, online package provides outstanding branding for large corporations and small businesses alike. It enables you to create a unique design and an option to pre-select a custom list of charities for clients to choose from.
        </div>
      </div>
      <div className={styles.right}>
        <img className={styles.iconDirection} src={srcIconIndicator} alt="Add a charitable dimension to your event! Multi- redeemable QR codes." />
        <img className={styles.bgRight} src={`${srcBackground}?${time}`} alt="CharityChoice enables great corporate branding and CRM with your company logo and message." />
      </div>
    </div>
  )
}
