import React, { useEffect, useState } from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import classnames from "classnames"
import { useAppData } from "../../../../Context/appData"
import styles from "./styles.module.scss"
import LoadingSpinner from "../../LoadingSpinner"

function CategoryFilter({ PreselCatID, color, sendDataToParent }) {
  const [categoryFilterName, setCategoryFilterName] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [categoryId, setCategoryId] = useState(null)
  const { charityCategories } = useAppData()
  const sendData = () => {
    sendDataToParent(categoryId)
  }

  useEffect(() => {
    if (!!charityCategories) {
      setIsLoading(false)
      setCategoryFilterName(
        !!PreselCatID || categoryId
          ? charityCategories.find(
              ({ CategoryId }) =>
                parseInt(CategoryId) === parseInt(PreselCatID) ||
                parseInt(CategoryId) === parseInt(categoryId)
            )?.CategoryName
          : "Filter By Category"
      )
    } else setIsLoading(true)
    sendData()
  }, [charityCategories, categoryId])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.wrapper}>
      <UncontrolledDropdown
        className={styles.filterWrapper}
        disabled={!!PreselCatID}>
        <DropdownToggle
          className={styles.filterTrigger}
          nav
          caret
          style={{ color }}>
          {categoryFilterName}
        </DropdownToggle>
        <DropdownMenu className={styles.filterList}>
          <DropdownItem
            className={classnames(styles.filterItem, styles.clear)}
            onClick={() => setCategoryId()}>
            Clear
          </DropdownItem>
          {!!charityCategories &&
            charityCategories.map(({ CategoryId, CategoryName }) => (
              <DropdownItem
                key={CategoryId}
                className={styles.filterItem}
                onClick={() => setCategoryId(CategoryId)}>
                {CategoryName}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default CategoryFilter
