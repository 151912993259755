import classnames from "classnames"
import React, { useState, useEffect } from "react"
import { GetHiddenCharities, GetHiddenCatCharityList } from "../../../Code/Data"
import { filterCharities, parseQuerystring } from "../../../Code/Utilities"
import { useAppData } from "../../../Context/appData"
import CharityDetailModal from "../CharityDetailModal"
import LoadingSpinner from "../LoadingSpinner"
import CategoryFilter from "./CategoryFilter"
import styles from "./styles.module.scss"
import { useLocation } from "react-router"
import { useRedemptionData } from "../../../Context/redemption"
import LocationFilter from "./LocationFilter"

export default function CharitySelector({
  isDirectDonation,
  color,
  query,
  charities,
  catId,
  SHCID,
  onUpdate,
  fromRedemption,
  afterAddCharity,
  setSelCharityList,
  unlimitted,
}) {
  const [searchQuery, setSearchQuery] = useState(query)
  const [viewCharity, setViewCharity] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { redeemCodeInfo } = useRedemptionData()
  const [location, setLocation] = useState()
  const [categoryIdFromChild, setCategoryIdFromChild] = useState()
  const [filterByMainList] = useState()
  const [filterByTop50List, setFilterByTop50List] = useState()
  const [filterByTop10, setFilterByTop10] = useState()
  const [filterByMajorList, setFilterByMajorList] = useState()
  const { fullCharityList } = useAppData()
  const [listToDisplay, setListToDisplay] = useState(fullCharityList)
  const { search } = useLocation()
  const [noResults, setNoResults] = useState(false)
  const PreselCatID = parseQuerystring(search)?.PreselCatID || catId

  const handleUpdateSearchQuery = e => {
    setSearchQuery(e.target.value)
  }
  const handleDataFromCatFilter = data => {
    setCategoryIdFromChild(data)
  }
  const getHiddenCatCharities = async () => {
    const result = await GetHiddenCatCharityList(SHCID)
    if (result.length > 0) {
      setListToDisplay([...listToDisplay, ...result])
    }
  }
  const handleUpdate = charity => {
    setViewCharity(false)
    onUpdate(charity)
    if (afterAddCharity) {
      afterAddCharity()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!!SHCID) {
      getHiddenCatCharities()
    }
    if (fullCharityList && charities && !fromRedemption) {
      const chars = fullCharityList.filter(charity =>
        charities.includes(charity.CharityId)
      )
      setSelCharityList(chars)
    }
    setIsLoading(!(!!fullCharityList && !!listToDisplay))
  }, [])

  useEffect(() => {
    if (fullCharityList && !listToDisplay) {
      setListToDisplay(fullCharityList)
    }
    setIsLoading(
      !(!!fullCharityList && fullCharityList.length && !!listToDisplay)
    )
  }, [fullCharityList, listToDisplay])

  useEffect(() => {
    if (!isLoading) {
      getFilteredCharityList()
    }
  }, [
    filterByMajorList,
    filterByMainList,
    filterByTop10,
    filterByTop50List,
    searchQuery,
    location,
    categoryIdFromChild,
    search,
    isLoading,
  ])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const getFilteredCharityList = async () => {
    let sortedList = [...fullCharityList]
    let filteredList = []

    if (
      !!redeemCodeInfo?.AddedCategoryIds &&
      redeemCodeInfo?.AddedCategoryIds.length > 0
    ) {
      let addedCatIdCharities = []
      for (let i = 0; i < redeemCodeInfo?.AddedCategoryIds?.length; i++) {
        let result = await GetHiddenCharities(
          redeemCodeInfo.AddedCategoryIds[i]
        )
        addedCatIdCharities = addedCatIdCharities.concat(result)
      }
      let list = redeemCodeInfo.OnlyShowAddedCategoryIds
        ? addedCatIdCharities
        : [...fullCharityList, ...addedCatIdCharities]
      let uniqueArr = [...new Set(list)]
      filteredList = uniqueArr
    } else {
      filteredList = [...fullCharityList]
    }
    const listname = filterByMainList ? "main" : "all"

    if (filterByMajorList) {
      filteredList = sortedList.filter(c => !c.IsLocal)
    }
    if (filterByTop50List) {
      filteredList = sortedList
        .filter(c => c.Rank === 1 || c.Rank === 2)
        .slice(0, 50)
    }
    if (filterByTop10) {
      filteredList = sortedList.filter(c => c.Rank === 2)
    }
    if (searchQuery) {
      filteredList = filteredList.filter(charity =>
        charity.CharityName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }
    if (PreselCatID || categoryIdFromChild || filterByMainList) {
      filteredList = filterCharities(
        filteredList,
        searchQuery,
        PreselCatID || categoryIdFromChild,
        listname
      )
    }
    if (location && !filterByMainList) {
      filteredList = filteredList.filter(
        charity =>
          charity.IsLocal &&
          charity.Locations &&
          charity.Locations.includes(location)
      )
    }

    const uniqueFilteredList = [
      ...new Map(filteredList.map(c => [c.CharityName, c])).values(),
    ]
    setListToDisplay(uniqueFilteredList)
    setNoResults(!uniqueFilteredList.length)
  }

  return (
    <>
      <div className="container-fluid">
        <div
          className={styles.header}
          style={{ width: isDirectDonation ? "auto" : "60vw" }}>
          <div className={styles.headerRow}>
            <div className={styles.inputSearch} style={{ borderColor: color }}>
              <input
                className={styles.input}
                placeholder="Search"
                value={searchQuery}
                onChange={handleUpdateSearchQuery}
              />
              <i className="fa-solid fa-magnifying-glass" style={{ color }} />
            </div>
            <CategoryFilter
              sendDataToParent={handleDataFromCatFilter}
              PreselCatID={PreselCatID}
              color={color}
            />
            {!filterByMainList && (
              <LocationFilter location={location} setLocation={setLocation} />
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.divFilters}>
              <div>
                <input
                  type="checkbox"
                  checked={filterByTop50List}
                  onChange={e => setFilterByTop50List(e.target.checked)}
                  name="filterByTop50List"
                />
                <label target="filterByTop50List">
                  {" "}
                  Only display top causes
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={filterByTop10}
                  onChange={e => setFilterByTop10(e.target.checked)}
                  name="filterByTop10"
                />
                <label target="filterByTop10">
                  Only display favorite popular causes
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={filterByMajorList}
                  onChange={e => setFilterByMajorList(e.target.checked)}
                  name="filterByMajorList"
                />
                <label target="filterByMajorList">
                  Only display major charities
                </label>
              </div>
            </div>
            {!isDirectDonation ? (
              <div className={styles.tip}>
                {unlimitted
                  ? "Choose your charities below."
                  : "Choose up to 3 charities to redeem below."}
              </div>
            ) : (
              <div className={styles.tip}>Select a charity below</div>
            )}

            <div
              className={styles.charityList}
              // style={{ width: isDirectDonation ? "46pc" : "100%" }}
            >
              {noResults ? (
                <div className={styles.noResults}>
                  No charities matched your search
                </div>
              ) : (
                listToDisplay.map(charity => {
                  const selected =
                    charities.includes(charity.CharityId) ||
                    charities.includes(charity)
                  return (
                    <div
                      key={charity.CharityId}
                      className={styles.charityItem}
                      onClick={() =>
                        !selected && !viewCharity && handleUpdate(charity)
                      }
                      value={charity.CharityId}>
                      <div
                        className={styles.btnView}
                        onClick={event => {
                          event.stopPropagation()
                          setViewCharity(charity)
                        }}>
                        <i className="fa-solid fa-eye" />
                        View
                      </div>
                      <div
                        className={classnames(styles.charityName, {
                          [styles.selected]: selected,
                        })}
                        style={{ color: selected && color }}>
                        {charity.CharityName}
                        </div>
                      {selected && (
                        <div className={styles.iconSelected} style={{ color }}>
                          <i className="fa-solid fa-circle-check" />
                        </div>
                      )}
                    </div>
                  )
                }) || <LoadingSpinner />
              )}
            </div>
          </div>
        </div>
        <CharityDetailModal
          charity={viewCharity}
          onClose={() => setViewCharity()}
          onSelect={handleUpdate}
        />
      </div>
    </>
  )
}
