import React from "react"
import styles from "./styles.module.scss"

export default function DisasterOfTheDayBanner({ show }) {
  return show && (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3>
          California Wildfires -  See &nbsp;
          <a href="https://blog.charitygiftcertificates.org/post/help-california-wildfire-victims"
            style={{ textDecoration: 'underline' }}
            target="__blank">
            Disaster Relief
          </a> &nbsp; for charities providing food, water, shelter and other vital services.
        </h3>
      </div>
    </div>
  ) || null;
}
