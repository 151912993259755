import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  ListGroupItem,
  Popover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import CreditCardLogos from "../../images/creditCardLogos.gif"

export default function Footer({ anchor }) {
  const sitemap = useRef(null); //to be able to access the current one
  const [feesPopover, setFeesPopover] = useState(false);
  const [shippingPopover, setShippingPopover] = useState(false);
  const [redeemPopover, setRedeemPopover] = useState(false);
  const [termsModal, setTermsModal] = useState(false);

  useEffect(() => {
    if (anchor && anchor.toLowerCase() === 'sitemap') {
      //timeout is used to prevent jumping back up.      
      setTimeout(() => {
        sitemap.current.scrollIntoView({ behavior: "smooth" });
      }, 1100);
    }
  }, [])

  const toggleFeesPopover = () => setFeesPopover(feesPopover ? false : true);
  const toggleShippingPopover = () => setShippingPopover(shippingPopover ? false : true);
  const toggleRedeemPopover = () => setRedeemPopover(redeemPopover ? false : true);
  const toggleTermsModal = () => { setFeesPopover(false); setTermsModal(termsModal ? false : true); }

  return <section id="sitemap"  ref={sitemap}>
    <a name="sitemap"></a>
    <div className="pt-0 pb-0 footer footer-dark">
      <div className="redcross">
        An Official Donation Site of the American Red Cross
      </div>
      <Container>
        <Row className="pt-3">
          <Col xs={12} sm={6} md={4} lg={3}>
            <h6 className="font-bold heading sitemap">
              General Information
            </h6>
            <div className="pl-2 mb-3">
              <ListGroupItem
                tag="a"
                href="/FAQs.aspx"
                className="link-item">
                FAQs
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/FAQs.aspx#Link24"
                className="link-item">
                Terms & Conditions
              </ListGroupItem>
              <ListGroupItem
                tag={Link}
                to="/DynamicPage?page=HomeHowDoesItWork"
                className="link-item">
                How Does It Work?
              </ListGroupItem>
              <ListGroupItem tag={Link} to="/WhatIs" className="link-item">
                What is a Charity Gift?
              </ListGroupItem>
              <ListGroupItem tag={Link} to="/About" className="link-item">
                About
              </ListGroupItem>
              <ListGroupItem
                tag={Link}
                to="/DynamicPage?page=HomeInTheNews"
                className="link-item">
                In the News
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Testimonials.aspx"
                className="link-item">
                Testimonials
              </ListGroupItem>
              <ListGroupItem
                tag={Link}
                to="/TopCharities"
                className="link-item">
                Top Chosen Charities
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                className="link-item btn-link"
                id="FeesPopover"
                onClick={toggleFeesPopover}>
                Fees
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                className="link-item btn-link"
                id="ShippingPopover"
                onClick={toggleShippingPopover}>
                Shipping and handling
              </ListGroupItem>
              <ListGroupItem tag="a" href="/DAF" className="link-item">
                Give with a DAF
              </ListGroupItem>
              <ListGroupItem tag="a" href="/Contact" className="link-item">
                Contact
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="https://blog.charitygiftcertificates.org"
                className="link-item"
                target="__blank">
                Blog
              </ListGroupItem>
            </div>
            <h6 className="font-bold heading">Services</h6>
            <div className="pl-2 mb-3">
              <ListGroupItem tag="a" href="/Login" className="link-item">
                Account Login
              </ListGroupItem>
              <ListGroupItem tag="a" href="/Login" className="link-item">
                Charity Login
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/AddNatLocalCharitiesRegistration/"
                className="link-item">
                Charity Registration
              </ListGroupItem>
              <ListGroupItem
                tag={Link}
                to="/CorporateSolutions"
                className="link-item">
                Business Gifts-branding
              </ListGroupItem>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <h6 className="font-bold heading">Charity Gift Options</h6>
            <div className="pl-2 mb-3">
              <ListGroupItem
                tag='a'
                href="/Purchase/PhysicalCards"
                className="link-item">
                Charity Gift Cards
              </ListGroupItem>
              <ListGroupItem
                tag={Link}
                to="/Purchase/DigitalCards"
                className="link-item">
                eCards
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Purchase/HonorCards"
                className="link-item">
                HonorCards
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/BridalRegistry"
                className="link-item">
                Wedding Registry and Bridal Favors
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Purchase/HonorCards"
                className="link-item">
                HonorCards
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Purchase/Favors-Swag-QR-Codes"
                className="link-item">
                Multi-Redeemable QR Codes
              </ListGroupItem>              
              <ListGroupItem tag="a" href="/redcross" className="link-item">
                American Red Cross Cards
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Purchase/DirectToCharityDonation"
                className="link-item">
                Make your donation to a charity
              </ListGroupItem>
              <ListGroupItem tag="a" href="/Purchase/DonateYourCard" className="link-item">
                Donate Merchant Gift Cards to Charity
              </ListGroupItem>
              <ListGroupItem
                tag={Link}
                to="/Purchase/DigitalCards"
                className="link-item">
                Last-minute charity gifts
              </ListGroupItem>
              <ListGroupItem
                tag='a'
                href="/superbowl/"
                className="link-item">
                Super Bowl Favor Idea
              </ListGroupItem>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <h6 className="font-bold heading">
              Corporate & Business Gifting
            </h6>
            <div className="pl-2 mb-3">
              <ListGroupItem
                tag={Link}
                to="/CorporateSolutions"
                className="link-item">
                Corporate Business Gifts
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Account/CreateUserRedemptionPage/"
                className="link-item">
                Custom Redemption Landing Pages
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Login?CA=1"
                className="link-item">
                Corporate Login Link
              </ListGroupItem>
            </div>
            <h6 className="font-bold heading">For Charities</h6>
            <div className="pl-2 mb-3">
              <ListGroupItem
                tag="a"
                href="/AddNatLocalCharitiesRegistration/"
                className="link-item">
                Charity Application
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/CharityLogin.aspx"
                className="link-item">
                Login to your Charity Account
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Reports/CharityDistributionReport.aspx"
                className="link-item">
                Access Redeemer Optin Information
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/CharityLogin.aspx"
                className="link-item">
                Edit Your Listing
              </ListGroupItem>
              <ListGroupItem tag="a" href="/Contact" className="link-item">
                Inquiries
              </ListGroupItem>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <h6 className="font-bold heading">Redeeming your Card</h6>
            <div className="pl-2 mb-3">
              <ListGroupItem tag="a" href="/Redeem" className="link-item">
                Redeem your code
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/Redeem/multi"
                className="link-item">
                Multiple redemption – many codes together
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                className="link-item btn-link"
                id="RedeemPopover"
                onClick={toggleRedeemPopover}>
                Redeem your rewards and incentive points
              </ListGroupItem>
              <ListGroupItem tag="a" href="/redcross" className="link-item">
                Redeem Red Cross Branded Cards
              </ListGroupItem>
            </div>
            <h6 className="font-bold heading">Charity Lists</h6>
            <div className="pl-2 mb-3">
              <ListGroupItem
                tag="a"
                href="/Charities.aspx"
                className="link-item">
                Over 250 Major Charities
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="/CharitiesLocalList.aspx"
                className="link-item">
                Many Local and More National Causes
              </ListGroupItem>
            </div>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className=" d-sm-block d-md-none">
        <Row className="justify-content-center text-align-center">
          <a
            className="nav-link m-2"
            href="https://www.facebook.com/CharityChoice"
            target="_blank"
            rel="noopener noreferrer"
            title="CharityChoice on Facebook">
            <i className="fab fa-facebook" />
          </a>
          <a
            className="nav-link m-2"
            href="https://twitter.com/CharityChoice"
            target="_blank"
            rel="noopener noreferrer"
            title="Follow us on Twitter">
            <i className="fab fa-twitter" />
          </a>
          <a
            className="nav-link m-2"
            href="https://www.instagram.com/charitychoicegiftcards/"
            target="_blank"
            rel="noopener noreferrer"
            title="CharityChoice on Instagram">
            <i className="fab fa-instagram" />
          </a>
        </Row>
      </Container>
      <Container>
        <Row>
          <div className="small col-centered text-align-center blab">
            <span>
              This CharityChoice website is operated by Special Kids Fund,
              <br />
              a nonprofit 501c3 organization, promoting charitable giving.
              <br />
              Tax ID 58-2550249{" "}
              <a
                href="//www.specialkidsfund.org"
                rel="noopener noreferrer"
                target="_blank"
                className="">
                www.specialkidsfund.org
              </a>
            </span>
          </div>
        </Row>
        <Row>
          <div className="col-centered text-align-center mt-3">
            <span>
              &copy; {` ${new Date().getFullYear()} `}
              <a href="/" className="footer-link">
                Charity Choice
              </a>
              . All rights reserved.
            </span>
          </div>
        </Row>
        <Row>
          <div className="col-centered text-align-center">
            <Container>
              <Row className="justify-content-md-center">
                <Col className="col-lg-2">
                  <img
                    src={CreditCardLogos}
                    style={{ height: 30 }}
                    alt="All major credit cards accepted"
                  />
                </Col>
                <Col className="col-md-auto">
                  <a
                    href="https://www.securitymetrics.com/site_certificate?id=296371&tk=b746a7c2f8435760ea4059b2eb8eb180"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      src="https://www.securitymetrics.com/static/img/site_certified_logos/Credit_Card_Safe_blue.png"
                      style={{ height: 50 }}
                      alt="SecurityMetrics Credit Card Safe"
                    />
                  </a>
                </Col>               
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      <Popover
        placement="right"
        isOpen={feesPopover}
        target="FeesPopover"
        toggle={toggleFeesPopover}
        onClick={toggleFeesPopover}>
        <PopoverHeader>
          Your purchase is a 100% charitable donation.
        </PopoverHeader>
        <PopoverBody>
          <p>
            Only 50 cents/card + 5% fundraising costs and the 3% credit card charge
            are deducted as fundraising costs before sending funds to the
            selected charities.
          </p>
          <p>Cards never expire.</p>
          <p className="btn-link" onClick={toggleTermsModal}>
            Terms and Conditions
          </p>
        </PopoverBody>
      </Popover>

      <Popover
        placement="right"
        isOpen={shippingPopover}
        target="ShippingPopover"
        toggle={toggleShippingPopover}
        onClick={toggleShippingPopover}>
        <PopoverHeader>Gift cards only:</PopoverHeader>
        <PopoverBody>
          <p>Expedited shipping available.</p>
          <p>
            No extra charge, no matter how many cards are shipped to one
            address.
          </p>
          <p>
            Shipping cards directly to recipients also available ($4.95 for
            first address and $1.95 per additional).
          </p>
          <p>
            All orders made before noon EST are shipped same day, after noon
            on next business day.
          </p>
        </PopoverBody>
      </Popover>
      <Popover
        placement="right"
        isOpen={redeemPopover}
        target="RedeemPopover"
        toggle={toggleRedeemPopover}
        onClick={toggleRedeemPopover}>
        <PopoverHeader>
          Redeem your rewards and incentive points
        </PopoverHeader>
        <PopoverBody>
          <p>
            Order a CharityChoice card from your rewards program. When you
            receive the ecert or gift card, return to this website and enter
            the code into the redemption code' box on the home page. If your
            program does not offer CharityChoice cards, email us to
            rewards@charity-choice.org.
          </p>
        </PopoverBody>
      </Popover>
      <Modal
        className="termsModal"
        isOpen={termsModal}
        toggle={toggleTermsModal}
        caption="CharityChoice Redeemption - Terms & Conditions">
        <ModalHeader toggle={toggleTermsModal}>
          CharityChoice Redeemption - Terms & Conditions
        </ModalHeader>
        <ModalBody className="small">
          <p>
            CharityChoice Gift Cards are recipient-designated charity gifts:
            a project of Special Kids Fund (501c3, EIN 58-2550249). Only the
            gift-recipient is entitled to designate the charity, which
            he/she may do at any time. If a card is purchased in order to
            use to donate to a charity, and not given as a gift; then the
            card-holder may redeem the card. Cards never lose value, nor
            expire. We always retain a reserve for honoring unredeemed
            cards. Purchases of gift cards are charity donations, that are
            100% tax deductible at the time of purchase; and, as such, are
            non-refundable.
          </p>
          <p>
          ALLOCATIONS: Funds are allocated quarterly to the chosen charities in one bulk check, per recipients' designations, sent from our parent nonprofit, Special Kids Fund. We deduct $0.50 per card [for cards $2 and up]+ 3% credit card processing and 5% fundraising costs. This is used towards the management of the CharityChoice fundraising platform, on behalf of our registered charities, and towards our nonprofit's mission to benefit special needs children.
          </p>
          <p>
            Note:  For cards accessed via third parties, not directly on our website, additional fundraising costs may be incurred.
          </p>
        
          <p>
            Please note that unlike for-profit websites and charity portals,
            CharityChoice is run by a charitable organization with a
            distinct cause and mission, providing for special needs
            children. So using our service is worthwhile all around!
          </p>
          <p>
            SHIPPING AND HANDLING: Physical Cards - $4.95 per order (for any
            quantity of cards shipped to a single address) for USPS First
            Class mailing with additional options for expedited shipping
            available at checkout.
          </p>
        </ModalBody>
      </Modal>
    </div>
  </section>
}
