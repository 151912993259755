import React, { useEffect, useState } from "react"
import { Button } from "../../.."
import { srcDefaultImage } from "../../../../images"
import bgImage from "../../../../images/DirectDonationStep2.jpg"
import CategoryFilter from "../../../Common/CharitySelector/CategoryFilter"
import { Modal } from "../../../../Components"
import CharitySelector from "../../../Common/CharitySelector"
import styles from "./styles.module.scss"
import { parseQuerystring } from "../../../../Code/Utilities"

function Step2({ onContinue, item, setItem, editMode, handleUpdate }) {
  const { CID } = parseQuerystring(window.location.search)
  const { SHCID } = parseQuerystring(window.location.search)
  const [searchQuery, setSearchQuery] = useState("")
  const [categoryIdFromChild, setCategoryIdFromChild] = useState(CID || null)
  const [modalOpen, setModalOpen] = useState(false)
  const [charity, setCharity] = useState(!!item?.charity ? item.charity : {})

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === "Enter") {
        setModalOpen(true)
      }
    }

    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [])

  const handleDataFromCatFilter = data => {
    if (!!data) {
      setCategoryIdFromChild(data)
      setModalOpen(true)
    }
  }

  const continueLbl = !!charity?.CharityName
    ? "Confirm & Continue"
    : "Select A Charity"

  const handleContinueFromModal = async () => {
    setModalOpen(false)
    setItem({ ...item, charity: charity })
    editMode && (await handleUpdate())
    onContinue()
  }
  const handleContinue = async () => {
    if (charity?.CharityName) {
      setItem({ ...item, charity: charity })
      editMode && (await handleUpdate())
      onContinue()
    } else setModalOpen(true)
  }

  return (
    <div className={styles.container}>
      <img
        src={bgImage}
        className={styles.img}
        width={
          window.innerWidth >= 768 && window.innerWidth <= 1120 ? "53vw" : "66%"
        }
        alt="Animal therapy"
      />
      <div className={styles.content}>
        <div className={styles.title}>Who would you like to donate to?</div>
        {!!charity?.CharityName && (
          <div className={styles.selectedCharityLbl}>
            Selected charity:&nbsp;
            {charity.CharityName?.length > 20
              ? charity.CharityName.substring(0, 20) + "..."
              : charity.CharityName}
            <span
              onClick={() => {
                setModalOpen(true)
              }}>
              change
            </span>
          </div>
        )}
        <div className={styles.catFilterWrapper}>
          <CategoryFilter
            PreselCatID={!!CID ? CID : charity?.CategoryId}
            sendDataToParent={handleDataFromCatFilter}
          />
        </div>
        <div className={styles.orDivider}>Or</div>

        <div className={styles.inputSearch}>
          <input
            type="text"
            placeholder="Search by name..."
            className={styles.input}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <i
            className="fa-solid fa-magnifying-glass"
            onClick={() => setModalOpen(true)}
          />
        </div>

        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <CharitySelector
            query={searchQuery || null}
            isDirectDonation={true}
            setSelCharityList={setCharity}
            catId={categoryIdFromChild || null}
            SHCID={SHCID}
            charities={!!charity ? [charity] : []}
            onUpdate={e => setCharity(e)}
            max={1}
          />
          {!!charity?.CharityName && (
            <>
              <div className={styles.lblCharity}>Your selected charity</div>
              <div className={styles.preselectedCharity}>
                <div className={styles.itemImage}>
                  <img
                    src={charity?.LogoUrl || srcDefaultImage}
                    alt={charity?.CharityName}
                  />
                  <div
                    className={styles.btnRemove}
                    onClick={() => setCharity(null)}>
                    <i className="fa-solid fa-xmark" />
                  </div>
                </div>
                <div className={styles.itemDetail}>
                  <div className={styles.itemName}>{charity?.CharityName}</div>
                  <div className={styles.itemDesc}>{charity?.CategoryName}</div>
                </div>
                <div className={styles.itemPrice}>${item.amount}</div>
              </div>
            </>
          )}
          <Button
            disabled={!charity?.CharityName}
            className={styles.modalContinue}
            style={{ width: "20%" }}
            onClick={handleContinueFromModal}>
            Confirm & Continue
          </Button>
        </Modal>

        <Button
          disabled={!charity}
          className={styles.continueBtn}
          onClick={handleContinue}>
          {continueLbl}
        </Button>
      </div>
    </div>
  )
}

export default Step2
