import React from "react"
import { Button } from "reactstrap"
import Slider1Cards from "../../../images/Slider1Cards.png"
import styles from "./styles.module.scss"

export default function LandingMainMini({time}) {
  return (
    <div className={styles.content}>
        <div className={styles.section1}>
          <img src={`${Slider1Cards}?${time}`} alt="Customized gift card graphic (digital and custom), to give-back for Birthdays, Holidays, and Sympathy." />
        </div>
        <div className={styles.section2}>
          <h1>Give more meaningful gifts, no matter the occasion...</h1>
          <h2>
            Your friends, and loved ones can redeem
            <br />
            for a charity that is close to their hearts
          </h2>
          <a href="/#GiveGiftCards">
            <Button outline color="primary">Shop Cards</Button>
          </a>
        </div>
        <div className={styles.section3}>    
        <h1>2,500+</h1>      
        Charities to choose from
        </div>
        <div className={styles.downArrow}></div>
      </div>
  )
}
