import classnames from "classnames"
import React, { useEffect, useState } from "react"
import Carousel from "react-multi-carousel"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { GetCards } from "../../../Code/Data"
import UploadCustomImage from "../../Common/UploadCustomImage"
import { usePurchaseData } from "../../../Context/purchase"
import { Loading } from "../.."
import styles from "./styles.module.scss"

/**
 * @typedef {{CardId: number, CardName: string, OccasionId: number, OccasionName:string, HasCustomSection:boolean, CustomSectionDefaultHtml:string,PresetDenomination:number,AltTag: string}} CardInfo
 */

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1650 },
    items: 6,
    slidesToSlide: 5
  },
  mediumDesktop: {
    breakpoint: { max: 1650, min: 1080 },
    items: 4,
    slidesToSlide: 3
  },
  smallDesktop: {
    breakpoint: { max: 1080, min: 768 },
    items: 3,
    slidesToSlide: 2

  },
  tablet: {
    breakpoint: { max: 768, min: 428 },
    items: 2,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 428, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
}

const CustomArrows = (props) => {
  const { carouselState, next, previous } = props
  const { totalItems, slidesToShow, currentSlide } = carouselState
  const currentShowing = currentSlide + slidesToShow
  return (
    <>
      {!!currentSlide && (
        <button className={styles.btnArrow} onClick={previous}>
          <i className="fa-solid fa-chevron-left" />
        </button>
      )}
      <button className={classnames(styles.btnArrow, styles.right)} onClick={next}>
        {currentShowing < totalItems && <i className="fa-solid fa-chevron-right"></i>}
      </button>
    </>
  )
}

/**
 * 
 * @param {Object} props - The component props.
 * @param {number} props.cardId - The ID of the selected card.
 * @param {function(CardInfo): void} props.onChange - Callback function that handles when a card is selected.
 * @param {function(Array<CardInfo>): void} props.onLoadOccasion - Callback function that handles when occasion cards are loaded.
 * @param {function(string): void} props.onUploadImage - Callback function that handles when an image is uploaded.
 * @param {string} props.occasionName - The name of the occasion.
 * @param {boolean} props.showHiddenOccasionId - Show the given hidden occasion ID.
 * @returns 
 */
function CardsByOccasionSelector({ cardId, onChange, onLoadOccasion, onUploadImage, occasionName, showHiddenOccasionId }) {
  const [loading, setLoading] = useState(false)
  /** @type {[Array<CardInfo>, function]} */
  const { digitalCardData } = usePurchaseData();
  const [cards, setCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalCard, setModalCard] = useState(null);

  const { occasion, cardList, customImage } = digitalCardData

  useEffect(() => {
    async function getCards() {
      setLoading(true)
      let cards = await GetCards(occasion.OccasionId,
        (showHiddenOccasionId && (showHiddenOccasionId === occasion.OccasionId)))
      if (cardId) {
        cards = [cards.find(c => c.CardId === cardId), ...cards.filter(c => c.CardId !== cardId)];
      }

      setCards(cards)
      setLoading(false)
      onLoadOccasion(cards);

      if (cards.length === 1) {
        handleChangeCard(cards[0])
      }
    }
    if (!!occasion && !!cardList && cardList[0].OccasionId === occasion.OccasionId) {
      const cards = cardId
        ? [cardList.find(c => c.CardId === cardId), ...cardList.filter(c => c.CardId !== cardId)]
        : cardList;
      setCards(cards);
    }
    else if (!!occasion) {
      getCards()
    }
  }, [occasion.OccasionId])

  /**
   * @param {CardInfo} card 
   */
  const handleChangeCard = card => {

    onChange(card);
  }

  const handleUploadImage = file => {
    onUploadImage(file.secure_url)
  }
  if (occasion?.OccasionId === 54) {
    return (
      <div className={styles.designSelector}>
        <div className={styles.designLabel}>
          Upload your own image
        </div>
        <div className={styles.designsContainer}>
          <UploadCustomImage CardType={occasionName} showGui={false} onChange={handleUploadImage}>
            <div className={styles.btnUpload}>
              {!!customImage ? (
                <img src={customImage} alt="Custom Card" />
              ) : (
                <>
                  <div className={styles.btnLabel}>Upload Your <br />Own Image</div>
                  <i className="fa fa-upload" />
                </>
              )}
            </div>
          </UploadCustomImage>
        </div>
      </div>
    )
  }

  if (loading) {
    return <Loading className={styles.cardLoader} />
  }

  return (
    <div className={styles.designSelector}>
      <div className={styles.designLabel}>
        Select your favorite design for the occasion
      </div>
      <div className={styles.clickLabel}>Double-click to view</div>
      <div className={styles.designsContainer}>
        <Carousel
          additionalTransfrom={0}
          infinite={true}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass={styles.carouselContainer}
          customButtonGroup={<CustomArrows />}
          draggable
          focusOnSelect={false}
          itemClass={styles.carouselItem}
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside
          responsive={responsive}
          shouldResetAutoplay
          sliderClass={styles.carouselTrack}
          slidesToSlide={1}
          swipeable
          showDots={true}
        >
          {cards.map((card, index) => (
            <div
              key={index}
              className={classnames(styles.imgCard, { [styles.active]: card.CardId === cardId })}
              onClick={() => handleChangeCard(card)}
              onDoubleClick={() => { setModalCard(card); setShowModal(true); }}
              title={`${card.CardName} - Click to select. Double-click to enlarge`}
            >
              <img
                src={`//res.cloudinary.com/charity-choice/c_limit,w_200/v1516642624/eCards/${card.CardId.toString()}.jpg`}
                alt={card.AltTag || `CharityChoice ${card.CardName} Card`}
              />
              {card.CardId === cardId && <div className={styles.lblAdded}>Selected</div>}
            </div>
          ))}
        </Carousel>
      </div>
      {!!modalCard && (
        <Modal
          isOpen={showModal}
          className={styles.cardDetailModal}
          toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(!showModal)}>
            {modalCard.CardName}
          </ModalHeader>
          <ModalBody>
            <img
              src={`https://res.cloudinary.com/charity-choice/c_limit,w_700,h_500/v1516642624/eCards/${modalCard.CardId.toString()}.jpg`}
              alt={modalCard.AltTag || `CharityChoice ${modalCard.CardName} Card`}
            />
          </ModalBody>
        </Modal>)}
    </div>
  )
}

export default CardsByOccasionSelector
