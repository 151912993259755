import React, { useEffect, useRef, useState } from "react"
import { useUserData } from "../../../Context/user"
import { useHistory } from "react-router"
import { RedBar } from "../../../Components"
import { ShoppingCartItemTypes } from "../../../Code/Data"
import { parseQuerystring } from "../../../Code/Utilities"
import { useCartData } from "../../../Context/cart"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import styles from "./styles.module.scss"

function Steps({ onBack }) {
  const [step, setStep] = useState(0)
  const steps = [Step1, Step2, Step3]
  const [item, setItem] = useState({})
  const [items, setItems] = useState([])
  const { loggedInUser } = useUserData()
  const [index, setIndex] = useState(null)
  const [shareWithCharities, setShareWithCharities] = useState(false)
  const [oldAmount, setOldAmount] = useState(0)
  const [errMsg, setErrMsg] = useState()
  const [editMode, setEditMode] = useState(false)
  const history = useHistory()
  const { cart } = useCartData()

  useEffect(() => {
    async function getQueryData() {
      const { DI } = parseQuerystring(window.location.search)
      setIndex(DI)
      if (DI >= 0 && cart?.Items) {
        const items = cart.Items.filter(
          ({ ShoppingCartItemTypeId }) =>
            ShoppingCartItemTypeId ===
            ShoppingCartItemTypes.DirectToCharityDonation
        ).map(({ PurchaseItemInfo }) => {
          const { ItemIndex, Charity, Denomination, ShareWithCharities } =
            PurchaseItemInfo
          setShareWithCharities(ShareWithCharities)
          return {
            index: ItemIndex,
            charity: Charity,
            amount: Denomination,
            shareWithCharities: ShareWithCharities,
          }
        })
        setItems(items)
        setItem(items[index])
        setOldAmount(items[index].amount)
        setEditMode(true)
      }
    }
    getQueryData()
  }, [cart])

  useEffect(() => {}, [items])

  const handleChangeAmount = e => {
    setItem({
      ...item,
      amount: e.target.value,
    })
  }

  const handleAdd = () => {
    if (!!item) {
      items.push(item)
      setItems([...items])
      setItem({})
    }
  }
  const handleUpdate = async () => {
    // setOldAmount(items[index].amount)
    return new Promise(resolve => {
      const updatedItems = [
        ...items.slice(0, index),
        item,
        ...items.slice(index + 1),
      ]
      setItems(updatedItems)
      setItem({})
      setOldAmount(0)
      // setEditMode(false)
      setIndex(null)
      resolve()
    })
  }
  const handleNewDonation = () => {
    setEditMode(false)
    setStep(0)
  }
  const handleRemoveItem = index => {
    setItems(prevItems => prevItems.filter((_, i) => i !== index))
  }

  const getOrderTotal = () => {
    const itemsTotal = items.reduce(
      (sum, item) => sum + Number(item?.amount || 0),
      0
    )
    if (item?.amount) {
      return itemsTotal + Number(item?.amount) - oldAmount
    }
    return itemsTotal - oldAmount
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    step ? setStep(step - 1) : onBack()
  }

  const handleBackToEdit = () => {
    setStep(0)
  }

  const StepComponent = steps[step]

  return (
    <div className={styles.container}>
      <RedBar>
        <div className={styles.redBarWrapper}>
          <div className={styles.stepLabel}>
            Step {step === 2 ? 2 : step + 1} of {steps.length}
            {step === steps.length - 1 && (
              <div className={styles.checkoutLabel}>Checkout is next</div>
            )}
          </div>{" "}
          {parseFloat(getOrderTotal() || 0) > 0 && (
            <div className={styles.price}>
              <i className="fa-solid fa-cart-shopping"></i>$
              {(getOrderTotal() || 0).toFixed(2)}
            </div>
          )}
        </div>
      </RedBar>

      <div className={styles.content}>
        <StepComponent
          onContinue={handleContinue}
          onBackToEdit={handleBackToEdit}
          onBack={handleBack}
          item={item}
          index={index}
          setItem={setItem}
          setItems={setItems}
          items={items}
          setErrMsg={setErrMsg}
          editMode={editMode}
          setEditMode={setEditMode}
          shareWithCharities={shareWithCharities}
          setShareWithCharities={setShareWithCharities}
          handleRemoveItem={handleRemoveItem}
          handleChangeAmount={handleChangeAmount}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          handleNewDonation={handleNewDonation}
        />
        {loggedInUser && step > 0 && (
          <div className={styles.divBackButton}>
            <button className={styles.btnStepBack} onClick={handleBack}>
              Go Back
              <i className="fa fa-undo" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Steps
