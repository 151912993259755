import React from "react"
import { Link } from 'react-router-dom'
import srcBackground from "../../../images/bg-charity-banner.png"
import Button from "../../Shared/Button"
import styles from "./styles.module.scss"

export default function CorporateBanner({time}) {
  return (
    <div className={styles.container}>
      <img className={styles.left} src={`${srcBackground}?${time}`} alt="Employees and clients will love your corporate culture of social responsibility. CSR." />
      <div className={styles.right}>
        <div className={styles.lblTitle}>When Meaningful Matters...</div>
        <div className={styles.title}>Build your Brand with Philanthropy</div>
        <div className={styles.subTitle}>
          Corporate Charitable Gifting, Business Gifts, Promotions, and Events
        </div>
        <div className={styles.description}>
          Giving back presents a positive image of social responsibility for your company (CSR) and boosts employee morale.
          We offer the most reasonably priced and robust customization in the industry!
          Learn more about our giving solutions for corporations and small businesses. It speaks volumes about your company's values.
        </div>
        <div className={styles.actions}>
          <Link to="/CorporateSolutions">
            <Button>Learn More</Button>
          </Link>
          <a href="/Login?CA=1">
            <Button outline color="yellow">Sign Up</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
