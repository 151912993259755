import React, { useEffect, useState } from "react"
import { Loading } from "../../.."
import { usePurchaseData } from "../../../../Context/purchase"
import { srcDefaultImage } from "../../../../images"
import { useHistory } from "react-router-dom"
import {
  AddDirectToCharityDonationsApi,
  UpdateDirectToCharityDonationsApi,
} from "../../../../Code/Data"
import bgImage from "../../../../images/DirectDonationStep3.jpg"
import styles from "./styles.module.scss"

function Step3({
  items,
  item,
  index,
  setItem,
  handleNewDonation,
  handleAdd,
  editMode,
  setEditMode,
  setErrMsg,
  shareWithCharities,
  setShareWithCharities,
  handleRemoveItem,
}) {
  const [loading, setLoading] = useState(false)
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const history = useHistory()
  const label =
    items?.length > 1 || (items.length === 1 && !!item)
      ? "Your selected charities"
      : "Your selected charity"
  // usePurchaseData()

  useEffect(() => {
    console.log("item and items", item, items)
  }, [])

  useEffect(() => {
    if (pendingSubmit && items.length > 0) {
      setTimeout(() => handleSubmit(), 0)
      setPendingSubmit(false)
    }
  }, [items])

  const handleAddDonation = () => {
    if (!editMode) handleAdd()
    setEditMode(prev => {
      if (prev) return false
    })
    setTimeout(() => {
      handleNewDonation()
    }, 0)
  }
  const handleRemoveCharity = item => {
    handleRemoveItem(item)
  }
  const handleContinue = async () => {
    console.log("item and edit mode", item, editMode)
    setPendingSubmit(true)
    if (item?.charity?.CharityName) {
      if (!editMode) {
        handleAdd()
      }
    } else {
      setTimeout(async () => {
        await handleSubmit()
        setPendingSubmit(false)
      }, 0)
    }
  }

  const handleSubmit = async () => {
    if (!items.length > 0) return
    const filteredItems = items.filter(item => item.charity)
    if (filteredItems.length === 0) return

    const payload = items
      .filter(({ charity }) => charity)
      .map(({ index, charity, amount }) => ({
        // ItemIndex: index,
        Charity: {
          ...charity,
          CategoryId: Number(charity.CategoryId),
        },
        ShareWithCharities: shareWithCharities,
        Quantity: 1,
        Denomination: amount,
      }))
    console.log("payload", payload)
    setLoading(true)
    const { Succeeded, ErrorMessage } = editMode
      ? await UpdateDirectToCharityDonationsApi(payload)
      : await AddDirectToCharityDonationsApi(payload)

    if (Succeeded) {
      window.location.href = "/Purchase/ShoppingCart"
      // history.push("/Purchase/ShoppingCart")
    } else {
      setErrMsg(ErrorMessage)
      console.log("Error message", ErrorMessage)
    }
    setLoading(false)
  }

  if (editMode && !(index >= 0)) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src={bgImage}
        width={
          window.innerWidth >= 768 && window.innerWidth <= 1120 ? "53vw" : "66%"
        }
        alt="Veterans day flag"
      />
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>Ready? Let's donate!</div>

          <div className={styles.lblCharity}>{label}</div>
          <div className={styles.donationList}>
            {items?.length > 0 &&
              items.map((mappedItem, mappedIndex) => {
                return (
                  mappedItem?.charity && (
                    <div
                      key={mappedIndex}
                      className={styles.preselectedCharity}>
                      <div className={styles.itemImage}>
                        <img
                          src={mappedItem?.charity?.LogoUrl || srcDefaultImage}
                          alt={mappedItem?.charity?.CharityName}
                        />

                        <div
                          className={styles.btnRemove}
                          onClick={() => handleRemoveCharity(mappedIndex)}>
                          <i className="fa-solid fa-xmark" />
                        </div>
                      </div>
                      <div className={styles.itemDetail}>
                        <div className={styles.itemName}>
                          {mappedItem?.charity.CharityName}
                        </div>
                        <div className={styles.itemDesc}>
                          {mappedItem?.charity?.CategoryName}
                        </div>
                      </div>
                      <div className={styles.itemPrice}>
                        ${parseFloat(mappedItem?.amount).toFixed(2)}
                      </div>
                    </div>
                  )
                )
              })}
            {item?.charity?.CharityName && (
              <div className={styles.preselectedCharity}>
                <div className={styles.itemImage}>
                  <img
                    src={item?.charity?.LogoUrl || srcDefaultImage}
                    // className={styles.img}
                    alt={item?.charity.CharityName}
                  />
                  <div
                    className={styles.btnRemove}
                    onClick={() => setItem(null)}>
                    <i className="fa-solid fa-xmark" />
                  </div>
                </div>
                <div className={styles.itemDetail}>
                  <div className={styles.itemName}>
                    {item?.charity?.CharityName}
                  </div>
                  <div className={styles.itemDesc}>
                    {item?.charity?.CategoryName}
                  </div>
                </div>
                <div className={styles.itemPrice}>
                  ${parseFloat(item?.amount).toFixed(2)}
                </div>
              </div>
            )}
          </div>
          <button className={styles.addDonationBtn} onClick={handleAddDonation}>
            Add Another Donation
          </button>

          <button
            disabled={!item && items.length < 1}
            className={styles.continueBtn}
            onClick={handleContinue}>
            Continue
          </button>

          <label className={styles.checkbox}>
            <input
              type="checkbox"
              name="ShareWithCharities"
              checked={shareWithCharities}
              onChange={() => setShareWithCharities(!shareWithCharities)}
            />
            I wish to share my information with the charities I selected.
          </label>
        </div>
      </div>
    </div>
  )
}

export default Step3
